import { all, takeEvery, take } from "redux-saga/effects";

import EmailAuthSaga from '../features/Login/redux/sagas';
import MenuCategorySaga from '../features/Menu/redux/sagas';
import DriverSaga from '../features/Driver/redux/sagas';
import OrdersSaga from '../features/Orders/redux/sagas';

//@BlueprintReduxSagaImportInsertion

function* helloSaga() {
  console.log("Hello from saga!");
}

export function* mainSaga() {
  yield all([
    takeEvery("TEST/ALO", helloSaga),
    // other sagas go here

    EmailAuthSaga,
    MenuCategorySaga,
    DriverSaga,
    OrdersSaga
    //@BlueprintReduxSagaMainInsertion
  ]);
}