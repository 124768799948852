import {all, takeLatest, put, call} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
    CATEGORY_REQUEST,
    CATEGORY_UPDATE,
    CATEGORY_ITEM_REQUEST,
    CATEGORY_ITEM_UPDATE,
    CATEGORY_ITEM_NAVIGATE_REQUEST,
    CATEGORY_ITEM_NAVIGATE,
    REVIEW_ITEM_REQUEST,
    REVIEW_ITEM_COMPLETE,
    REVIEW_ITEM_ERROR,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_UPDATE,
    ADD_CATEGORY_ERROR,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_UPDATE,
    ADD_PRODUCT_ERROR,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_UPDATE,
    UPDATE_PRODUCT_ERROR,
    CATEGORY_ITEM_VARIANT_UPDATE,
    CATEGORY_ITEM_VARIANT_REQUEST,
    UPDATE_VARIANT_REQUEST,
    UPDATE_VARIANT_ERROR,
    UPDATE_VARIANT_UPDATE,
    UPDATE_VARIANT_ADD,
    UPDATE_CATEGORY_UPDATE,
    UPDATE_CATEGORY_ERROR,
    UPDATE_CATEGORY_REQUEST,
    DELETE_CATEGORY_REQUEST,
    DELETE_PRODUCT_REQUEST,
    DELETE_CATEGORY_REQUEST_ERROR,
    DELETE_CATEGORY_REQUEST_SUCCESS,
    DELETE_PRODUCT_REQUEST_SUCCESS,
    DELETE_PRODUCT_REQUEST_ERROR,
    CATEGORY_ITEM_VARIANT_ADD,
    UPDATE_CATEGORY_SORT_REQUEST,
    UPDATE_PRODUCT_SORT_REQUEST
} from './constants';
import {request, setupHttpConfig} from '../../../utils/http';
import {AxiosResponse} from 'axios';
import {Category} from '../../../types';

function deleteCategoryRequest({id}) {
    const res = request.delete('/api/v1/category/' + id);
    return res;
}

function deleteProductRequest({id}) {
    const res = request.delete('/api/v1/item/' + id);
    return res;
}

const getRequest = ({place}: {place: number}) => request.get(`/api/v1/category/?place=${place}`)

function getVarientRequest() {
    const res = request.get('/api/v1/itemvariant-menu-web/');
    return res;
}

function* getCategory(action: {place_id: number, type: string}) {
    console.log('get category action', action)
    setupHttpConfig();
    try {
        const {status, data} = yield call(getRequest, {place: action.place_id});
        if (status === 200) {
            yield put({type: CATEGORY_UPDATE, data: data});

        }
    } catch (error) {
        console.log("getCategory", error);
        // todo add errors with similar structure in backend
    }
}

function getItemRequest({id}) {
    const res = request.get('/api/v1/item/?category=' + id);
    return res;
}

function* getCategoryItem(action) {
    setupHttpConfig();
    console.log("getCategoryItem:: ", action)
    try {
        const {status, data} = yield call(getItemRequest, {id: action.id});
        console.log("getCategoryItem:: ", status, data)
        if (status === 200) {
            yield put({type: CATEGORY_ITEM_UPDATE, data: data});
        }
    } catch (error) {
        console.log("getCategoryItem", error);
        // todo add errors with similar structure in backend
    }
}

function* getItemVarients(action) {
    setupHttpConfig();
    try {
        const {status, data} = yield call(getVarientRequest);
        if (status === 200) {
            yield put({type: CATEGORY_ITEM_VARIANT_UPDATE, data: data});
        }
    } catch (error) {
        console.log("getItemVarients", error);
        // todo add errors with similar structure in backend
    }
}

function* navigateToMenuItems(action) {
    yield put({type: CATEGORY_ITEM_NAVIGATE, data: action.item});
}

function sendReviewPost({data}) {
    return request.post('/api/v1/post-review/', {data});
}

function updateCategorySortRequest({data}) {
    return request.post('/api/v1/category-sort/', {data});
}

function updateProductSortRequest({data}) {
    return request.post('/api/v1/item-sort/', {data});
}

function itemVariantPost({data}) {
  
    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("description", data.description);
    bodyFormData.append("price", data.price);
    bodyFormData.append("quantity", '0');
    if(data.imageFile){
      bodyFormData.append("image", data.imageFile);
    }
    bodyFormData.append("item", data.item);
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
  
    if (data.id) {
        return request.patch('/api/v1/itemvariant-menu-web/' + data.id + "/", bodyFormData);
    } else {
        delete data["id"];
        return request.post('/api/v1/itemvariant-menu-web/', bodyFormData);
    }
}

function postCategory({data}: {data: Category}) {
    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("description", data.description);
    bodyFormData.append("image", data.image);
    bodyFormData.append("icon", data.icon);
    bodyFormData.append("available", data.available);
    bodyFormData.append("place", `${data.place}`)
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.post<Category>('/api/v1/category/', bodyFormData);
}

function updateCategory({data}) {

    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("description", data.description);
    if (data.image != undefined) {
        bodyFormData.append("image", data.image);
        bodyFormData.append("icon", data.image);
    }
    bodyFormData.append("available", data.available);
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.patch('/api/v1/category/' + data.id + '/', bodyFormData);
}

function postProduct({data}) {

    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("category", data.category);
    bodyFormData.append("quantity", '0');
    bodyFormData.append("price", data.price);
    bodyFormData.append("description", data.description);
    bodyFormData.append("image", data.image);
    bodyFormData.append("available", data.available);
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.post('/api/v1/item/', bodyFormData);
}

function updateProduct({data}) {

    var bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("category", data.category);
    bodyFormData.append("description", data.description);
    bodyFormData.append("price", data.price);
    bodyFormData.append("quantity", data.quantity);
    if (data.image != undefined) {
        bodyFormData.append("image", data.image);
    }
    bodyFormData.append("available", data.available);
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.patch('/api/v1/item/' + data.id + '/', bodyFormData);
}

function* sendReview(action) {

    try {
        console.log("sendReview:: ", action)
        const {status, data} = yield call(sendReviewPost, {data: action.data});
        console.log("sendReview::", data);
        if (status === 200) {
            yield put({type: REVIEW_ITEM_COMPLETE, reviewResponse: data});
        } else {
            yield put({type: REVIEW_ITEM_ERROR, error: "Error in submitting review"});
        }
    } catch (error) {
        //console.log(error);
        // todo add errors with similar structure in backend
        yield put({type: REVIEW_ITEM_ERROR, error: "Error in submitting review"});
    }

}

function* addCategory(action: {type: string, data: Category}) {
    try {
        console.log("addCategory:: ", action)
        const {status, data}: AxiosResponse<Category> = yield call(postCategory, {data: action.data});
        console.log("addCategory::", status, data);
        if (status === 201) {
            toast.success("New Category Added Successfully..");
            yield put({type: ADD_CATEGORY_UPDATE, addCategoryResponse: data});
        } else {
            yield put({type: ADD_CATEGORY_ERROR, error: "Picture or fields are missing."});
            toast.error("Picture or fields are missing.")
        }
    } catch (error) {
        console.log(error);
        toast.error("Picture or fields are missing.")
        // todo add errors with similar structure in backend
        yield put({type: ADD_CATEGORY_ERROR, error: "Picture or fields are missing."});
    }
}

function* addProduct(action) {
    try {
        console.log("addProduct:: ", action);
        let itemVariants = action.data.itemVarients;
        const {status, data} = yield call(postProduct, {data: action.data});
        console.log("addProduct::", status, data);
        for(let i = 0; i < itemVariants.length ;i++){
          let itemVariant = itemVariants[i];
          const resp = yield call(itemVariantPost, {data: {...itemVariant,item:data.id}});
          if(resp.status === 201){
            yield put({type: CATEGORY_ITEM_VARIANT_ADD, data: resp.data});
          }
        }
        if (status === 201) {
            toast.success("New Product Added Successfully..");
            yield put({type: ADD_PRODUCT_UPDATE, addProductResponse: data});
        } else {
            yield put({type: ADD_PRODUCT_ERROR, error: "Error in submitting review"});
            toast.error("Picture or fields are missing.")
        }
        yield put({type: CATEGORY_ITEM_VARIANT_REQUEST});
    } catch (error) {
        console.log(error);
        toast.error("Picture or fields are missing.")
        // todo add errors with similar structure in backend
        yield put({type: ADD_PRODUCT_ERROR, error: "Picture or fields are missing."});
    }
}

function* updateCategoryRequest(action) {
    try {
        console.log("updateCategoryRequest:: ", action)
        const {status, data} = yield call(updateCategory, {data: action.data});
        if (status === 200) {
            toast.success("Category Saved Successfully..");
            yield put({type: UPDATE_CATEGORY_UPDATE, category: data});
        } else {
            yield put({type: UPDATE_CATEGORY_ERROR, error: "Picture or fields are missing."});
            toast.error("Picture or fields are missing.")
        }
    } catch (error) {
        console.log(error);
        toast.error("Picture or fields are missing.")
        // todo add errors with similar structure in backend
        yield put({type: UPDATE_PRODUCT_ERROR, error: "Picture or fields are missing."});
    }
}

function* updateProductRequest(action) {
    try {
        console.log("updateProduct:: ", action)
        const {status, data} = yield call(updateProduct, {data: action.data});
        if (status === 200) {
            toast.success("Product Saved Successfully..");
            yield put({type: UPDATE_PRODUCT_UPDATE, addProductResponse: data});
        } else {
            yield put({type: UPDATE_PRODUCT_ERROR, error: "Picture or fields are missing."});
            toast.error("Picture or fields are missing.")
        }
    } catch (error) {
        console.log(error);
        toast.error("Picture or fields are missing.")
        // todo add errors with similar structure in backend
        yield put({type: UPDATE_PRODUCT_ERROR, error: "Picture or fields are missing."});
    }
}

function* updateProductVarientRequest(action) {

    try {
        const itemVarients = action.data.itemVarients;
        const id = action.data.id;
        const items = []
        for (let i = 0; i < itemVarients.length; i++) {
            const e = itemVarients[i];
            try {
                const {status, data} = yield call(itemVariantPost, {
                    data: {
                        id: e.id
                            ? e.id
                            : null,
                        name: e.name,
                        quantity: e.quantity,
                        price: e.price,
                        item: id,
                        description: e.description,
                        imageFile: e.imageFile
                    }
                });
                if (status === 201) {
                    toast.success("Varient added Successfully..");
                    items.push(data);
                } else if (status === 200) {
                    toast.success("Varient updated Successfully..");
                    items.push(data);
                } else {
                    toast.error("Varient Fields are missing..")
                }
            } catch (ex) {
                toast.error("Varient Fields are missing..")
            }
        }
        yield put({type: UPDATE_VARIANT_ADD, addProductResponse: items});
    } catch (ex) {
        yield put({type: UPDATE_VARIANT_ERROR, error: "Varient Fields are missing."});
    }
}

function* deleteCategory(action) {
    try {
        const {status, data} = yield call(deleteCategoryRequest, {id: action.id});
        if (status === 204) {
            toast.success("Category Deleted Successfully..");
            yield put({type: DELETE_CATEGORY_REQUEST_SUCCESS, id: action.id});
        } else {
            yield put({type: DELETE_CATEGORY_REQUEST_ERROR, error: "Error in deleting category."});
            toast.error("Error in deleting category")
        }
    } catch (error) {
        console.log(error);
        toast.error("Error in deleting category")
        // todo add errors with similar structure in backend
        yield put({type: DELETE_CATEGORY_REQUEST_ERROR, error: "Error in deleting category."});
    }
}

function* deleteProduct(action) {
    try {
        const {status, data} = yield call(deleteProductRequest, {id: action.id});
        if (status === 204) {
            toast.success("Product Deleted Successfully..");
            yield put({type: DELETE_PRODUCT_REQUEST_SUCCESS, id: action.id});
        } else {
            yield put({type: DELETE_PRODUCT_REQUEST_ERROR, error: "Error in deleting product."});
            toast.error("Error in deleting product")
        }
    } catch (error) {
        console.log(error);
        toast.error("Error in deleting product")
        // todo add errors with similar structure in backend
        yield put({type: DELETE_PRODUCT_REQUEST_ERROR, error: "Error in deleting product."});
    }
}

function* sortCategory(action) {
    try {
        const {status, data} = yield call(updateCategorySortRequest, action.data);
        console.log("sortCategory::",{status, data})
    } catch (error) {
        console.log(error);
    }
}

function* sortProduct(action) {
    try {
        const {status, data} = yield call(updateProductSortRequest, action.data);
        console.log("sortProduct::",{status, data})
    } catch (error) {
        console.log(error);
    }
}


export default all([
    takeLatest(CATEGORY_REQUEST, getCategory),
    takeLatest(CATEGORY_ITEM_REQUEST, getCategoryItem),
    takeLatest(CATEGORY_ITEM_VARIANT_REQUEST, getItemVarients),
    takeLatest(CATEGORY_ITEM_NAVIGATE_REQUEST, navigateToMenuItems),
    takeLatest(REVIEW_ITEM_REQUEST, sendReview),
    takeLatest(ADD_CATEGORY_REQUEST, addCategory),
    takeLatest(UPDATE_CATEGORY_REQUEST, updateCategoryRequest),
    takeLatest(ADD_PRODUCT_REQUEST, addProduct),
    takeLatest(UPDATE_PRODUCT_REQUEST, updateProductRequest),
    takeLatest(UPDATE_VARIANT_REQUEST, updateProductVarientRequest),

    takeLatest(DELETE_CATEGORY_REQUEST, deleteCategory),
    takeLatest(DELETE_PRODUCT_REQUEST, deleteProduct),
    //sort function
    takeLatest(UPDATE_CATEGORY_SORT_REQUEST, sortCategory),
    takeLatest(UPDATE_PRODUCT_SORT_REQUEST, sortProduct)
]);
