import * as actions from "./constants";

const initialState = {
    drivers: []
};

const DriverReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.DRIVER_REQUEST_SUCCESS:
            console.log("DriverReducer::", action.data)
            state
                .drivers
                .push(action.data);
            return {
                ...state,
                drivers: state
                    .drivers
                    .slice()
            };

        case actions.DRIVER_GET_REQUEST_SUCCESS:
            return {
                ...state,
                drivers: action.data
            };
        default:
            return state;
    }
};

export default DriverReducer;