export const DRIVER_ADD_REQUEST = "DRIVER/DRIVER_ADD_REQUEST";
export const DRIVER_REQUEST_SUCCESS = "DRIVER/DRIVER_REQUEST_SUCCESS";
export const DRIVER_REQUEST_ERROR = "DRIVER/DRIVER_REQUEST_ERROR";

export const DRIVER_GET_REQUEST = "DRIVER/DRIVER_GET_REQUEST";
export const DRIVER_GET_REQUEST_SUCCESS = "DRIVER/DRIVER_GET_REQUEST_SUCCESS";
export const DRIVER_GET_REQUEST_ERROR = "DRIVER/DRIVER_GET_REQUEST_ERROR";

export const DRIVER_EDIT_REQUEST = "DRIVER/DRIVER_EDIT_REQUEST";
export const DRIVER_EDIT_REQUEST_SUCCESS = "DRIVER/DRIVER_EDIT_REQUEST_SUCCESS";
export const DRIVER_EDIT_REQUEST_ERROR = "DRIVER/DRIVER_EDIT_REQUEST_ERROR";

export const DRIVER_SUSPEND_REQUEST = "DRIVER/DRIVER_SUSPEND_REQUEST";
export const DRIVER_SUSPEND_REQUEST_SUCCESS = "DRIVER/DRIVER_SUSPEND_REQUEST_SUCCESS";
export const DRIVER_SUSPEND_REQUEST_ERROR = "DRIVER/DRIVER_SUSPEND_REQUEST_ERROR";

export const ACTIVATE_SUSPEND_REQUEST = "DRIVER/ACTIVATE_SUSPEND_REQUEST";
export const ACTIVATE_SUSPEND_REQUEST_SUCCESS = "DRIVER/ACTIVATE_SUSPEND_REQUEST_SUCCESS";
export const ACTIVATE_SUSPEND_REQUEST_ERROR = "DRIVER/ACTIVATE_SUSPEND_REQUEST_ERROR";