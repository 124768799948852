export const CATEGORY_REQUEST = "CATEGORY/CATEGORY_REQUEST";
export const CATEGORY_UPDATE = "CATEGORY/CATEGORY_UPDATE";

export const STATE_DEFAULT = "CATEGORY/STATE_DEFAULT";

export const ADD_CATEGORY_REQUEST = "CATEGORY/ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_UPDATE = "CATEGORY/ADD_CATEGORY_UPDATE";
export const ADD_CATEGORY_ERROR = "CATEGORY/ADD_CATEGORY_ERROR";

export const UPDATE_CATEGORY_REQUEST = "CATEGORY/UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_UPDATE = "CATEGORY/UPDATE_CATEGORY_UPDATE";
export const UPDATE_CATEGORY_ERROR = "CATEGORY/UPDATE_CATEGORY_ERROR";

export const ADD_PRODUCT_REQUEST = "CATEGORY/ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_UPDATE = "CATEGORY/ADD_PRODUCT_UPDATE";
export const ADD_PRODUCT_ERROR = "CATEGORY/ADD_PRODUCT_ERROR";

export const UPDATE_PRODUCT_REQUEST = "CATEGORY/UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_UPDATE = "CATEGORY/UPDATE_PRODUCT_UPDATE";
export const UPDATE_PRODUCT_ERROR = "CATEGORY/UPDATE_PRODUCT_ERROR";

export const UPDATE_VARIANT_REQUEST = "CATEGORY/UPDATE_VARIANT_REQUEST";
export const UPDATE_VARIANT_ADD = "CATEGORY/UPDATE_VARIANT_ADD";
export const UPDATE_VARIANT_UPDATE = "CATEGORY/UPDATE_VARIANT_UPDATE";
export const UPDATE_VARIANT_ERROR = "CATEGORY/UPDATE_VARIANT_ERROR";

export const CATEGORY_ITEM_REQUEST = "CATEGORY/CATEGORY_ITEM_REQUEST";
export const CATEGORY_ITEM_UPDATE = "CATEGORY/CATEGORY_ITEM_UPDATE";

export const CATEGORY_ITEM_VARIANT_REQUEST = "CATEGORY/CATEGORY_ITEM_VARIANT_REQUEST";
export const CATEGORY_ITEM_VARIANT_UPDATE = "CATEGORY/CATEGORY_ITEM_VARIANT_UPDATE";
export const CATEGORY_ITEM_VARIANT_ADD = "CATEGORY/CATEGORY_ITEM_VARIANT_ADD";

export const SET_SPECIFIC_ITEM = "CATEGORY/SET_SPECIFIC_ITEM";

export const CATEGORY_ITEM_NAVIGATE_REQUEST = "CATEGORY/CATEGORY_ITEM_NAVIGATE_REQUEST";
export const CATEGORY_ITEM_NAVIGATE = "CATEGORY/CATEGORY_ITEM_NAVIGATE";


export const UPDATE_CART = "CATEGORY/UPDATE_CART";
export const CLEAR_CART = "CATEGORY/CLEAR_CART";

export const REVIEW_ITEM_REQUEST = "ITEM/REVIEW_ITEM_REQUEST";
export const REVIEW_ITEM_COMPLETE = "ITEM/REVIEW_ITEM_COMPLETE";
export const REVIEW_ITEM_ERROR = "ITEM/REVIEW_ITEM_ERROR";

export const DELETE_CATEGORY_REQUEST = "CATEGORY/DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_REQUEST_SUCCESS = "CATEGORY/DELETE_CATEGORY_REQUEST_SUCCESS";
export const DELETE_CATEGORY_REQUEST_ERROR = "CATEGORY/DELETE_CATEGORY_REQUEST_ERROR";

export const DELETE_PRODUCT_REQUEST = "CATEGORY/DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_REQUEST_SUCCESS = "CATEGORY/DELETE_PRODUCT_REQUEST_SUCCESS";
export const DELETE_PRODUCT_REQUEST_ERROR = "CATEGORY/DELETE_PRODUCT_REQUEST_ERROR";

export const UPDATE_CATEGORY_SORT_REQUEST = "CATEGORY/UPDATE_CATEGORY_SORT_REQUEST";
export const UPDATE_CATEGORY_SORT_REQUEST_SUCCESS = "CATEGORY/UPDATE_CATEGORY_SORT_REQUEST_SUCCESS";
export const UPDATE_CATEGORY_SORT_REQUEST_ERROR = "CATEGORY/UPDATE_CATEGORY_SORT_REQUEST_ERROR";

export const UPDATE_PRODUCT_SORT_REQUEST = "CATEGORY/UPDATE_PRODUCT_SORT_REQUEST";
export const UPDATE_PRODUCT_SORT_REQUEST_SUCCESS = "CATEGORY/UPDATE_PRODUCT_SORT_REQUEST_SUCCESS";
export const UPDATE_PRODUCT_SORT_REQUEST_ERROR = "CATEGORY/UPDATE_PRODUCT_SORT_REQUEST_ERROR";
