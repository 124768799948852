export const ORDER_GET_REQUEST = "ORDER/ORDER_GET_REQUEST";
export const ORDER_GET_REQUEST_SUCCESS = "ORDER/ORDER_GET_REQUEST_SUCCESS";
export const ORDER_GET_REQUEST_ERROR = "ORDER/ORDER_GET_REQUEST_ERROR";

export const ORDER_ASSIGN_REQUEST = "ORDER/ORDER_ASSIGN_REQUEST";
export const ORDER_ASSIGN_REQUEST_SUCCESS = "ORDER/ORDER_ASSIGN_REQUEST_SUCCESS";
export const ORDER_ASSIGN_REQUEST_ERROR = "ORDER/ORDER_ASSIGN_REQUEST_ERROR";

export const ORDER_STATUS_CHANGE_REQUEST = "ORDER/ORDER_STATUS_CHANGE_REQUEST";
export const ORDER_STATUS_CHANGE_REQUEST_SUCCESS = "ORDER/ORDER_STATUS_CHANGE_REQUEST_SUCCESS";
export const ORDER_STATUS_CHANGE_REQUEST_ERROR = "ORDER/ORDER_STATUS_CHANGE_REQUEST_ERROR";