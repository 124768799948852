import * as actions from "./constants";

const initialState = {
    orders: []
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.ORDER_GET_REQUEST_SUCCESS:
            return {
                ...state,
                orders: action.data
            };
        default:
            return state;
        case actions.ORDER_ASSIGN_REQUEST_SUCCESS:
            const CIndex = state
                .orders
                .findIndex((obj => obj.id == action.order.id));
            state.orders[CIndex] = action.order;
            return {
                ...state,
                orders: state
                    .orders
                    .slice()
            };
    }
};

export default OrderReducer;