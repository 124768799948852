import { combineReducers } from "redux";

import EmailAuthReducer from '../features/Login/redux/reducers';
import MenuCategoryReducer from '../features/Menu/redux/reducers';
import DriverReducer from '../features/Driver/redux/reducers';
import OrderReducer from '../features/Orders/redux/reducers';
/**
 * You can import more reducers here
 */


//@BlueprintReduxImportInsertion

export const combinedReducers = combineReducers({
  blank: (state, action) => {
    if (state == null) state = [];
    return state;
  },

  EmailAuth: EmailAuthReducer,
  MenuCategory: MenuCategoryReducer,
  Driver: DriverReducer,
  Order: OrderReducer

  //@BlueprintReduxCombineInsertion
});